import { extendVariants, Button as NextUIButton } from "@nextui-org/react";
import { Spinner } from "ds/ui";
import { colorVariants } from "ds/utils";
import { forwardRef } from "react";

const ExtendedButton = extendVariants(NextUIButton, {
  variants: {
    variant: {
      solid: "disabled:bg-gray-200",
      bordered: "border-small bg-transparent",
      light: "!font-semibold !gap-2 bg-transparent",
      faded: "disabled:bg-gray-200 disabled:border-gray-300 border-small",
      ghost:
        "disabled:bg-gray-200 disabled:border-gray-300 border-small bg-transparent",
    },
    size: {
      xs: "font-medium px-3 py-3 min-w-16 h-8 text-tiny gap-2 rounded-small [&>svg]:w-4 [&>svg]:!stroke-sm",
      sm: "font-medium px-3 py-3 min-w-16 h-8 text-small gap-2 rounded-small [&>svg]:w-4 [&>svg]:!stroke-sm",
      md: "font-medium px-3 py-4 min-w-20 h-10 text-small gap-3 rounded-small [&>svg]:w-4 [&>svg]:!stroke-md",
      lg: "font-medium px-3 py-4 min-w-24 h-12 text-medium gap-3 rounded-small [&>svg]:w-5 [&>svg]:!stroke-lg",
    },
    color: {
      default: "opacity-1 disabled:text-base-disabled",
      primary: "opacity-1 disabled:text-base-disabled",
      success: "opacity-1 disabled:text-base-disabled",
      warning: "opacity-1 disabled:text-base-disabled",
      danger: "opacity-1 disabled:text-base-disabled",
    },
    fullWidth: {
      true: "w-full",
    },
    isDisabled: {
      true: "pointer-events-none",
    },
    isInGroup: {
      true: "[&:not(:first-child):not(:last-child)]:rounded-none",
    },
    isIconOnly: {
      true: "px-0 !gap-0",
      false: "",
    },
    disableAnimation: {
      true: "!transition-none data-[pressed=true]:scale-100",
      false:
        "transition-transform-colors-opacity motion-reduce:transition-none",
    },
  },
  defaultVariants: {
    size: "md",
    variant: "solid",
    color: "default",
    fullWidth: false,
    isDisabled: false,
    isInGroup: false,
  },
  compoundVariants: [
    // solid / color
    {
      variant: "solid",
      color: "default",
      class: [
        colorVariants.solid.default,
        "data-[hover=true]:bg-primary-dark data-[hover=true]:text-primary-foreground-dark",
      ],
    },
    {
      variant: "solid",
      color: "primary",
      class: [
        colorVariants.solid.primary,
        "data-[hover=true]:bg-primary-dark data-[hover=true]:text-primary-foreground-dark",
      ],
    },
    {
      variant: "solid",
      color: "success",
      class: [
        colorVariants.solid.success,
        "data-[hover=true]:bg-success-dark data-[hover=true]:text-primary-foreground-dark",
      ],
    },
    {
      variant: "solid",
      color: "warning",
      class: [
        colorVariants.solid.warning,
        "data-[hover=true]:bg-warning-dark data-[hover=true]:text-warning-foreground-dark",
      ],
    },
    {
      variant: "solid",
      color: "danger",
      class: [colorVariants.solid.danger, "data-[hover=true]:bg-danger-dark"],
    },
    // shadow / color
    {
      variant: "shadow",
      color: "default",
      class: colorVariants.shadow.default,
    },
    {
      variant: "shadow",
      color: "primary",
      class: colorVariants.shadow.primary,
    },
    {
      variant: "shadow",
      color: "success",
      class: colorVariants.shadow.success,
    },
    {
      variant: "shadow",
      color: "danger",
      class: colorVariants.shadow.danger,
    },
    // bordered / color
    {
      variant: "bordered",
      color: "default",
      class: colorVariants.bordered.default,
    },
    {
      variant: "bordered",
      color: "primary",
      class: colorVariants.bordered.primary,
    },
    {
      variant: "bordered",
      color: "success",
      class: colorVariants.bordered.success,
    },
    {
      variant: "bordered",
      color: "danger",
      class: colorVariants.bordered.danger,
    },
    // flat / color
    {
      variant: "flat",
      color: "default",
      class: colorVariants.flat.default,
    },
    {
      variant: "flat",
      color: "primary",
      class: colorVariants.flat.primary,
    },
    {
      variant: "flat",
      color: "success",
      class: colorVariants.flat.success,
    },
    {
      variant: "flat",
      color: "danger",
      class: colorVariants.flat.danger,
    },
    // faded / color
    {
      variant: "faded",
      color: "default",
      class: [
        colorVariants.faded.default,
        "data-[hover=true]:bg-default-light",
      ],
    },
    {
      variant: "faded",
      color: "primary",
      class: [
        colorVariants.faded.primary,
        "data-[hover=true]:bg-primary-light",
      ],
    },
    // light / color
    {
      variant: "light",
      color: "default",
      class: [
        colorVariants.light.default,
        "data-[hover=true]:bg-default-light",
        "data-[hover=true]:text-default-dark",
      ],
    },
    {
      variant: "light",
      color: "primary",
      class: [
        colorVariants.light.primary,
        "data-[hover=true]:bg-primary-light",
        "data-[hover=true]:text-primary-dark",
      ],
    },
    {
      variant: "light",
      color: "success",
      class: [
        colorVariants.light.success,
        "data-[hover=true]:bg-success-light",
        "data-[hover=true]:text-success-dark",
      ],
    },
    {
      variant: "light",
      color: "warning",
      class: [
        colorVariants.light.warning,
        "data-[hover=true]:bg-warning-light",
        "data-[hover=true]:text-warning-dark",
      ],
    },
    {
      variant: "light",
      color: "danger",
      class: [
        colorVariants.light.danger,
        "data-[hover=true]:bg-danger-light",
        "data-[hover=true]:text-danger-dark",
      ],
    },
    // ghost / color
    {
      variant: "ghost",
      color: "default",
      class: [colorVariants.ghost.default, "data-[hover=true]:!bg-default"],
    },
    {
      variant: "ghost",
      color: "primary",
      class: [
        colorVariants.ghost.primary,
        "data-[hover=true]:!bg-primary data-[hover=true]:!text-primary-foreground",
      ],
    },
    {
      variant: "ghost",
      color: "success",
      class: [
        colorVariants.ghost.success,
        "data-[hover=true]:!bg-success data-[hover=true]:!text-success-foreground",
      ],
    },
    {
      variant: "ghost",
      color: "warning",
      class: [
        colorVariants.ghost.warning,
        "data-[hover=true]:!bg-warning data-[hover=true]:!text-warning-foreground",
      ],
    },
    {
      variant: "ghost",
      color: "danger",
      class: [
        colorVariants.ghost.danger,
        "data-[hover=true]:!bg-danger data-[hover=true]:!text-danger-foreground",
      ],
    },
    {
      isIconOnly: true,
      size: "xs",
      class: "min-w-8 w-8 h-8 [&>svg]:w-4",
    },
    {
      isIconOnly: true,
      size: "sm",
      class: "min-w-8 w-8 h-8",
    },
    {
      isIconOnly: true,
      size: "md",
      class: "min-w-10 w-10 h-10",
    },
    {
      isIconOnly: true,
      size: "lg",
      class: "min-w-12 w-12 h-12",
    },
  ],
});

type ExtendedButtonProps = React.ComponentProps<typeof ExtendedButton>;

type Variant = Extract<
  ExtendedButtonProps["variant"],
  "ghost" | "faded" | "solid" | "light"
>;
type Color = Extract<
  ExtendedButtonProps["color"],
  "primary" | "success" | "warning" | "danger"
>;

export type ButtonProps = Omit<ExtendedButtonProps, "variant" | "color"> & {
  color?: Color;
  variant?: Variant;
};

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (props, ref) => {
    return (
      <ExtendedButton
        {...props}
        ref={ref}
        startContent={props.isLoading ? undefined : props.startContent}
        endContent={props.isLoading ? undefined : props.endContent}
        spinner={<Spinner size={props.size !== "lg" ? "xs" : "sm"} />}
      />
    );
  },
);

Button.displayName = "RecareUI.Button";
